<template>
  <v-dialog persistent @keydown.esc="close" v-model="bDialogNewQuantity" :width="screenWidth + '%'">
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-edit-information">
          <p class="title-edit-information">Actualizar cantidad</p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-current-amount">
          <div class="content-title">
            <p class="p-title">Cantidad actual de la materia</p>
          </div>
          <div class="content-text">
            <p class="p-text">
              {{ formatMillon(iCurrentAmount) }}
              <span class="content-kg">Kg</span>
            </p>
          </div>
        </div>
        <div class="content-texts-fields">
          <!-- <div class="content-title-currency">
            <p class="p-title-currency">Cantidad de transacción</p>
          </div> -->
          <div class="content-currency">
            <div class="content-select-operation">
              <v-select v-model="sOperation" :items="itemsOperation" item-text="text" item-value="value"
                label="Operación" class="global-select" color="var(--primary-color-border-menu-profile)"
                background-color="var(--primary-color-menu)" outlined></v-select>
            </div>
            <div class="content-text-field-currency">
              <input-currency-global :flagModal="bDialogNewQuantity" labelGlobal="Nueva cantidad de la materia"
                classDynamic="global-text-field" :bOutlined="true" :bImportantData="false"
                @changeMontoGlobal="changeMontoGlobal" :modelValue="value" sSuffix="Kg" sPrefix="" />
              <!-- <p class="content-input-label">Nueva cantidad de material</p> -->

              <!-- <div class="sectionInputValue">
                <currency-input
                  class="global-text-field global-input-currency mb-3"
                  v-model="value"
                  :options="options"
                  :min="0"
                  @keydown="onKeyDown($event)"
                />
              </div> -->
              <!-- <div class="content-measurement-units">
                <p class="measurement-units">
                  <span class="content-currency-kg">Kg</span>
                </p>
              </div> -->
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-text>
        <v-divider class="divider-sidebar-global mb-3"></v-divider>

        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn v-if="bEdit" @click="close" class="global-btn-neutral">
              Cancelar
            </v-btn>
            <v-btn v-else @click="close" class="global-btn-neutral">
              Descartar actualización
            </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn class="global-btn-primary" @click="changeInventory()" :disabled="bEdit" :loading="bLoading">
              Actualizar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogNewQuantity: Boolean,
    iCurrentAmount: Number,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      bEdit: true,
      dataImg: null,
      value: 0,
      sOperation: "+",
      itemsOperation: [
        { text: "Agregar", value: "+" },
        { text: "Remover", value: "-" },
      ],
      options: {
        // currency: "EUR",
        // currencyDisplay: "symbol",
        // hideCurrencySymbolOnFocus: true,
        // hideGroupingSeparatorOnFocus: true,
        // hideNegligibleDecimalDigitsOnFocus: true,
        // autoDecimalDigits: false,
        // autoSign: true,
        // useGrouping: true,
        // accountingSign: false,

        locale: "en-US",
        currency: "USD",
        currencyDisplay: "hidden",
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        autoSign: true,
        useGrouping: true,
        accountingSign: false,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    onKeyDown(evt) {
      if (evt.keyCode == 189 || evt.keyCode == 109) {
        event.preventDefault();
      }
    },
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 55;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 40;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 30;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 30;
      }
    },
    uploadImg: function (e) {
      const files = e.target.files;
      this.dataImg = files;
    },
    removeImg: function () {
      this.dataImg = null;
    },
    maskedFile: function (file) {
      return URL.createObjectURL(file);
    },
    close() {
      this.$emit("setDialogNewQuantity");
      // this.$store.commit("setDialogNewQuantity", {
      //   active: false,
      //   iCurrentAmount: 0,
      // });
      this.sOperation = "+";
      this.value = 0;
      this.bLoading = false;

      this.dataImg = null;
    },
    fieldNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    formatMillon(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    changeInventory() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.bAdminMaterial) {
            const payload = {
              dQuantity: parseFloat(this.sOperation + this.value),
            },
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            DB.post(
              `${URI}/api/sp/v1/raw-materials/${this.$route.params.id}/inventory`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;
                this.$store.commit("refresher", true);
                this.close();
                this.Success(response.data.message);
              })
              .catch((error) => {
                this.bLoading = false;
                this.Error(error.response.data);
              });
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },

    discardChange() {
      this.value = this.iCurrentAmount;
      this.bEdit = true;
      this.validateForm();
    },
    validateForm() {
      this.bEdit = this.value === 0.0;
    },
    changeMontoGlobal(val) {
      this.value = parseFloat(val);
    },
  },
  computed: {
    bShowMaterial() {
      return this.$store.state.bShowMaterial;
    },
    bAdminMaterial() {
      return this.$store.state.bAdminMaterial;
    },
  },
  watch: {
    bDialogNewQuantity() {
      if (this.bDialogNewQuantity) {
        // this.value = this.iCurrentAmount;
      }
    },
    value() {
      if (this.value === null) {
        this.value = 0;
      }
      this.validateForm();
    },
  },
};
</script>
<style scoped>
.sectionInputValue .v-text-field {
  margin-top: 0px !important;
}

.content-text-field-currency {
  width: 100%;
}

.content-title-edit-information {
  width: 100%;
}

.content-img {
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
}

.content-image {
  width: 70%;
  height: 180px !important;
  position: relative;
  border: 1px solid #70707080;
  border-radius: 20px;
}

.button-delete-item {
  position: absolute;
  right: 60px;
  bottom: 5px;
  min-width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color-btn-neutral-red) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.button-edit-item {
  position: absolute;
  right: 15px;
  bottom: 5px;
  min-width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color-btn-primary) !important;
  padding: 0px !important;
  border-radius: 10px;
}

.button-add-img {
  width: 70% !important;
  height: 180px !important;
  background: transparent 0% 0% no-repeat padding-box !important;
  border: 2px dashed var(--primary-color-divider);
  border-radius: 20px;
  opacity: 1;
}

.title-edit-information {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
  word-break: normal;
}

.content-current-amount {
  /* display: flex; */
  width: 100%;
  margin-bottom: 15px;
}

.content-texts-fields {
  /* display: flex; */
  width: 100%;
}

.content-title {
  justify-content: start;
  margin-bottom: 0px;
  /* width: 50%; */
}

.p-title {
  text-align: start;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-bottom: 0px;
}

.content-text {
  /* width: 50%; */
}

.p-text {
  text-align: start;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
  margin-right: 5px;
}

.content-kg {
  margin-left: 10px;
  color: var(--primary-color-text-yellow);
}

.content-currency-kg {
  color: var(--primary-color-text-yellow);
}

.content-title-currency {
  justify-content: start;
  width: 40%;
}

.p-title-currency {
  text-align: start;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  font-size: 14px;
}

.content-select-operation {
  margin-bottom: 15px;
  /* width: 130px;
  min-width: 130px;
  max-width: 130px;
  margin-right: 10px; */
}

.content-currency {
  /* display: flex; */
  /* width: 60%; */
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 100%;
}

.content-btn-second {
  width: 100%;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-current-amount {
    display: block;
    width: 100%;
  }

  .content-title {
    justify-content: start;
    margin-bottom: 0px;
  }

  .content-text {
    width: 100%;
  }

  .content-texts-fields {
    display: block;
    width: 100%;
  }

  .content-title-currency {
    width: 100%;
  }

  .content-currency {
    width: 100%;
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>